import MinimalLayout from 'layout/MinimalLayout';
const DocsRoutes = {
  path: '/docs',
  element: <MinimalLayout />,
  children: [
    {
      path: 'guides',
      element: (
        <iframe key="guides" src="/docs/guides/index.html" title="Guides" style={{ width: '100%', height: '100vh', border: 'none' }} />
      )
    },
    {
      path: 'guides/agreement/privacy',
      element: (
        <iframe
          key="privacy"
          src="/docs/guides/agreement/privacy.html"
          title="Privacy"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'guides/agreement/user',
      element: (
        <iframe key="user" src="/docs/guides/agreement/user.html" title="User" style={{ width: '100%', height: '100vh', border: 'none' }} />
      )
    },
    {
      path: 'guides/problems/break/',
      element: (
        <iframe
          key="break"
          src="/docs/guides/problems/break.html"
          title="Break"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'guides/problems/gpts/',
      element: (
        <iframe key="gpts" src="/docs/guides/problems/gpts.html" title="gpts" style={{ width: '100%', height: '100vh', border: 'none' }} />
      )
    },
    {
      path: 'guides/problems/models/',
      element: (
        <iframe
          key="models"
          src="/docs/guides/problems/models.html"
          title="models"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'guides/activity',
      element: (
        <iframe
          key="activity"
          src="/docs/guides/activity.html"
          title="Activity"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'guides/quickstart',
      element: (
        <iframe
          key="quickstart"
          src="/docs/guides/quickstart.html"
          title="Quickstart"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools',
      element: <iframe key="tools" src="/docs/tools/index.html" title="Tools" style={{ width: '100%', height: '100vh', border: 'none' }} />
    },
    {
      path: 'tools/academic/xueshu/',
      element: (
        <iframe
          key="xueshu"
          src="/docs/tools/academic/xueshu.html"
          title="Academic"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/academic/zotero/',
      element: (
        <iframe
          key="zotero"
          src="/docs/tools/academic/zotero.html"
          title="Zotero"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/broswer/google/',
      element: (
        <iframe
          key="google"
          src="/docs/tools/broswer/google.html"
          title="Google"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/broswer/sider/',
      element: (
        <iframe key="sider" src="/docs/tools/broswer/sider.html" title="Sider" style={{ width: '100%', height: '100vh', border: 'none' }} />
      )
    },
    {
      path: 'tools/broswer/translate/',
      element: (
        <iframe
          key="translate"
          src="/docs/tools/broswer/translate.html"
          title="Translate"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/code/codegpt',
      element: (
        <iframe
          key="codegpt"
          src="/docs/tools/code/codegpt.html"
          title="Codegpt"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/code/easycode',
      element: (
        <iframe
          key="easycode"
          src="/docs/tools/code/easycode.html"
          title="Easycode"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/cross/chatx',
      element: (
        <iframe key="cross" src="/docs/tools/cross/chatx.html" title="Cross" style={{ width: '100%', height: '100vh', border: 'none' }} />
      )
    },
    {
      path: 'tools/web/chatproxy',
      element: (
        <iframe
          key="chatproxy"
          src="/docs/tools/web/chatproxy.html"
          title="Chatproxy"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    },
    {
      path: 'tools/web/nextchat/',
      element: (
        <iframe
          key="nextchat"
          src="/docs/tools/web/nextchat.html"
          title="Nextchat"
          style={{ width: '100%', height: '100vh', border: 'none' }}
        />
      )
    }
  ]
};
export default DocsRoutes;
